<template>
  <ul class="grid t3">
    <li>
      <div class="form_wrap t2">
        <span class="">{{ title }}</span>
        <!--0826 수정 시작-->
        <input class="wid120" type="text" placeholder="시작일" ref="dp" v-model="sdate" />
        <div class="input_calendar inline">
          <input type="text" id="" name="" placeholder="종료일" @click.prevent="selRangePicker" v-model="edate" />
          <button class="tbl_icon calendar" @click.prevent="onClick($event)">달력</button>
        </div>
        <!--0826 수정 끝-->
      </div>
    </li>
    <li class="btn_rdo">
      <input type="radio" id="rdo1" name="rdo" checked>
      <label for="rdo1">{{ $t('comp.102') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" id="rdo2" name="rdo" @click="selOneWeek">
      <label for="rdo2">{{ $t('comp.103') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" id="rdo3" name="rdo" @click="selOneMonth">
      <label for="rdo3">{{ $t('comp.104') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" id="rdo4" name="rdo" @click="selMonth">
      <label for="rdo4">{{ $t('comp.105') }}</label>
    </li>
  </ul>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateRangePickerForOog',

  props: {
    title: { type: String, default: '', required: false },
    frdt: { type: String, default: '', required: false },
    todt: { type: String, default: '', required: false }
  },

  data () {
    return {
      sdate: this.frdt,
      edate: this.todt,
      format: 'YYYY.MM.DD'
    }
  },

  watch: {
    sdate () {
      $(this.$refs.dp).data('daterangepicker').setStartDate(this.sdate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    },

    edate () {
      $(this.$refs.dp).data('daterangepicker').setEndDate(this.edate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    },

    frdt () {
      this.sdate = this.frdt
      $(this.$refs.dp).data('daterangepicker').setStartDate(this.sdate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    },

    todt () {
      this.edate = this.todt
      $(this.$refs.dp).data('daterangepicker').setEndDate(this.edate)
      this.$emit('change', this.sdate.replaceAll('.', ''), this.edate.replaceAll('.', ''))
    }
  },

  mounted () {
    $(this.$refs.dp).daterangepicker({
      autoUpdateInput: false,
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: this.format
      }
    }, (s, e, l) => {
      this.sdate = s.format(this.format)
      this.edate = e.format(this.format)
    })
  },

  methods: {
    selRangePicker () {
      this.$refs.dp.click()
    },

    selOneWeek () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-6, 'd').format(this.format)
    },

    selOneMonth () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.add(-1, 'M').add(1, 'd').format(this.format)
    },

    selMonth () {
      let now = moment()
      this.edate = now.format(this.format)
      this.sdate = now.set('date', 1).format(this.format)
    },

    onClick (e) {
      this.$refs.dp.focus()
    }
  }
}
</script>

<style scoped>
.input_calendar.mid, .input_calendar.mid input {
    width: 200px;
}
</style>
